
import { defineComponent, ref, watch, onMounted } from "vue";
import { object, string } from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { useRoute, useRouter } from "vue-router";

interface DistrictOption {
  value: string;
  text: string;
}

interface UpazilaOption {
  value: string;
  text: string;
}

export default defineComponent({
  mixins: [apiEndpoint],
  name: "BeneficiaryModal",
  props: {
    data: { type: Object },
    loadData: { type: Function },
  },
  setup(props) {
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      id: "",
      updated_by: "",
      beneficiary_profile_pic: null as File | null,
      created_by: VueCookieNext.getCookie("_user_id"),
      ia_partner_id: VueCookieNext.getCookie("_ia_partner_id")
        ? VueCookieNext.getCookie("_ia_partner_id")
        : 0,
      company_name: "",
      beneficiary_name: "",
      father_name: "",
      mother_name: "",
      mobile_no: "",
      nid: "",
      gender: "",
      marital_status: "",
      dob: "",
      religion: "",
      household_name: "",
      household_mobile_number: "",
      female_household_head: "",
      household_occupation: "",
      household_annual_income: "",
      household_number: "",
      total_male_member: "",
      total_children_age: "",
      total_adolocent_age: "",
      total_female_member: "",
      beneficiary_ethinicity: "",
      geo_division_id: "",
      geo_district_id: "",
      district_name: "",
      geo_upazila_id: "",
      upazila_name: "",
      present_address: "",
      permanent_address: "",
      parents_present_address: "",
      parents_permanent_address: "",
      sector: "",
      occupation: "",
      employment_occupation: "",
      occupation_level: "",
      institute_name: "",
      training_division_id: "",
      training_district_id: "",
      training_district_name: "",
      training_title: "",
      training_start_date: "",
      training_end_date: "",
      course_fee: "",
      convince_fee: "",
      administrative_fee: "",
      // course_subject: "",
      // stipend: "",
      // grant_amount: "",
      // grant_received_date: "",
      // grant_type: "",
      school_level: "",
      highest_class: "",
      training_status: "",
      employment_status: "",
      annual_salary: "",
      investment: "",
      annual_income: "",
      // training_skill: "",
      training_last_three_year: "",
      vocational_training: "",
      is_disable: "",
      wearing_glasses_difficulty: "",
      hearing_aid_difficulty: "",
      difficulty_walking: "",
      difficulty_remembering: "",
      difficulty_with_selfcare: "",
      difficulty_communicating: "",
      family_disability_status: "",
      employment_company_name: "",
      employment_designation: "",
      employment_date_joining: "",
      employment_office_address: "",
      employment_type: "",
      employment_sector: "",
      company_status: "",
      mcp_name: "",
      mcp_mobile: "",
      mcp_gender: "",
      pre_employment_company_name: "",
      pre_employment_designation: "",
      pre_employment_date_joining: "",
      pre_employment_office_address: "",
      pre_employment_annual_salary: "",
      pre_employment_investment: "",
      pre_employment_annual_income: "",
      pre_employment_type: "",
      pre_employment_sector: "",
      disableFamilyMembers: [
        {
          disable_person_name: "",
          disable_member_gender: "",
          beneficary_relationship: "",
          wearing_glasses_difficulty: false,
          hearing_aid_difficulty: false,
          difficulty_walking: false,
          difficulty_remembering: false,
          difficulty_with_selfcare: false,
          difficulty_communicating: false,
        },
      ],
    });

    const schema = object().shape({
      // company_name: string().required("Institute Name is required"),
      beneficiary_name: string().required("Name is required"),
      // father_name: string().required("Father Name is required"),
      // mother_name: string().required("Mother Name is required"),
      // mobile_no: string().required("Mobile Number is required"),
      // nid: string().required("NID is required"),
      // gender: string().required("NID is required"),
      // marital_status: string().required("NID is required"),
    });

    const errors = ref({});
    const divisionOptions = ref([]);
    const districtOptions = ref<DistrictOption[]>([]);
    const upazilaOptions = ref<UpazilaOption[]>([]);
    const occupationOptions = ref([]);
    const employmentOccupationOptions = ref([]);
    const preEmploymentOccupationOptions = ref([]);
    const employee_url = ref<string | null>(null);

    const employeePhoto = (e) => {
      const file: File | null = e.target.files[0] || null;
      formData.value.beneficiary_profile_pic = file;

      if (file) {
        employee_url.value = URL.createObjectURL(file);
      } else {
        employee_url.value = null;
      }
    };

    const addMoreMember = (index) => {
      const newCard = {
        disable_person_name: "",
        disable_member_gender: "",
        beneficary_relationship: "",
        wearing_glasses_difficulty: false,
        hearing_aid_difficulty: false,
        difficulty_walking: false,
        difficulty_remembering: false,
        difficulty_with_selfcare: false,
        difficulty_communicating: false,
      };

      formData.value.disableFamilyMembers.push(newCard);
    };

    const getDivision = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_DIVISION_LIST
        );

        divisionOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.division_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivisionWistDistrict = async (districtType) => {
      try {
        console.log(districtType);
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DISTRICT_LIST,
          {
            geo_division_id: districtType == 'training' ? formData.value.training_division_id : formData.value.geo_division_id,
          }
        );

        console.log(response);

        if(districtType == 'training'){
          districtOptions.value = response.data.data.map((option) => ({
            value: option.id,
            text: option.district_name_eng,
          }));
        }else {
          districtOptions.value = response.data.data.map((option) => ({
            value: option.id,
            text: option.district_name_eng,
          }));
        }


      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDistrictWistUpazila = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_UPAZILA_LIST,
          {
            geo_district_id: formData.value.geo_district_id,
          }
        );
        upazilaOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.upazila_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getSectorWiseOccupation = async (sectorType) => {
      try {
        const sector_name = formData.value.sector;
        if (sectorType == "sector") {
          const sector_name = formData.value.sector;
        } else if (sectorType == "employment_sector") {
          const sector_name = formData.value.employment_sector;
        } else if (sectorType == "pre_employment_sector") {
          const sector_name = formData.value.pre_employment_sector;
        }

        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_OCCUPATION_LIST,
          {
            sector_name: sector_name,
          }
        );

        if (sectorType == "sector") {
          occupationOptions.value = response.data.data.map((option) => ({
            value: option.id,
            text: option.title,
          }));
        }
        else if(sectorType == "employment_sector"){
          employmentOccupationOptions.value = response.data.data.map(
              (option) => ({
                value: option.id,
                text: option.title,
              })
          );
        }
        else if(sectorType == "pre_employment_sector") {
          preEmploymentOccupationOptions.value = response.data.data.map(
            (option) => ({
              value: option.id,
              text: option.title,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    watch(
      () => formData.value.geo_division_id,
      (newComponent) => {
        if (newComponent !== "") {
          getDivisionWistDistrict('geo');
        }
      }
    );

    watch(
        () => formData.value.training_division_id,
        (newComponent) => {
          if (newComponent !== "") {
            getDivisionWistDistrict('training');
          }
        }
    );

    watch(
      () => formData.value.geo_district_id,
      (newComponent) => {
        if (newComponent !== "") {
          getDistrictWistUpazila();
        }
      }
    );

    watch(
      () => formData.value.sector,
      (newComponent) => {
        if (newComponent !== "") {
          getSectorWiseOccupation("sector");
        }
      }
    );
    watch(
      () => formData.value.employment_sector,
      (newComponent) => {
        if (newComponent !== "") {
          getSectorWiseOccupation("employment_sector");
        }
      }
    );
    watch(
      () => formData.value.pre_employment_sector,
      (newComponent) => {
        if (newComponent !== "") {
          getSectorWiseOccupation("pre_employment_sector");
        }
      }
    );
    const save = async () => {
      try {
        submitButton.value?.setAttribute("data-kt-indicator", "on");

        ApiService.setHeaderforImage();

        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }

        const districtText: string | undefined = districtOptions.value.find(
          (option) => option.value === formData.value.geo_district_id
        )?.text;

        if (districtText) {
          formData.value.district_name = districtText;
        }

        const trainingDistrictText: string | undefined = districtOptions.value.find(
            (option) => option.value === formData.value.training_district_id
        )?.text;

        if (trainingDistrictText) {
          formData.value.training_district_name = trainingDistrictText;
        }

        const upazilaText: string | undefined = upazilaOptions.value.find(
          (option) => option.value === formData.value.geo_upazila_id
        )?.text;

        if (upazilaText) {
          formData.value.upazila_name = upazilaText;
        }

        // await schema.validate(formData.value, { abortEarly: false });

        // Create FormData and append the image
        const formDataObj = new FormData();

        // Loop through formData.value and append each key-value pair
        Object.entries(formData.value).forEach(([key, value]) => {
          // Check if the value is a File (for the image field)
          if (value instanceof File) {
            formDataObj.append(key, value);
          } else {
            // Convert boolean values to 'Yes' or 'No'
            if (typeof value === "boolean") {
              formDataObj.append(key, value ? "Yes" : "No");
            } else {
              formDataObj.append(key, value);
            }
          }
        });

        ApiService.post(
          apiEndpoint.data().VUE_APP_BENEFICIARY_STORE,
          formDataObj
        )
          .then((response) => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });

              console.log(response);

              // router.push('/beneficiary');
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        // formData.value = {
        //   company_name: "",
        //   beneficiary_name: "",
        //   father_name: "",
        //   mother_name: "",
        //   mobile_no: "",
        //   nid: "",
        //   gender: "",
        //   marital_status: "",
        //   earning_member: "",
        //   disability_member: "",
        // };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    const loadDataForUpdate = async () => {
      try {
        const beneficiaryId = route.params.id;
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_BENEFICIARY_INFO,
          {
            id: beneficiaryId,
          }
        );

        formData.value = {
          ...formData.value,
          ...response.data.data,
          female_household_head:
            response.data.data.female_household_head === "Yes",
          // training_skill: response.data.data.training_skill === "Yes",
          training_last_three_year:
            response.data.data.training_last_three_year === "Yes",
          vocational_training: response.data.data.vocational_training === "Yes",
          is_disable: response.data.data.is_disable === "Yes",
          wearing_glasses_difficulty:
            response.data.data.wearing_glasses_difficulty === "Yes",
          hearing_aid_difficulty:
            response.data.data.hearing_aid_difficulty === "Yes",
          difficulty_walking: response.data.data.difficulty_walking === "Yes",
          difficulty_remembering:
            response.data.data.difficulty_remembering === "Yes",
          difficulty_with_selfcare:
            response.data.data.difficulty_with_selfcare === "Yes",
          difficulty_communicating:
            response.data.data.difficulty_communicating === "Yes",
          family_disability_status:
            response.data.data.family_disability_status === "Yes",
        };

        // Populate other form data properties as needed
      } catch (error) {
        console.error("Error loading data for update:", error);
      }
    };

    watch(
      () => route.params.id,
      (newBeneficiaryId) => {
        if (newBeneficiaryId) {
          loadDataForUpdate();
        }
      }
    );

    onMounted(() => {
      getDivision();
      if (route.params.id) {
        loadDataForUpdate();
      }
    });

    return {
      divisionOptions,
      districtOptions,
      upazilaOptions,
      occupationOptions,
      employmentOccupationOptions,
      preEmploymentOccupationOptions,
      formData,
      errors,
      save,
      employeePhoto,
      employee_url,
      addMoreMember,
      route,
      submitButton,
    };
  },
});
